.question {
  width: 100%;
  margin: 8px;
  padding: 8px;
  border: 1px solid #f0f0f0;
}

.option {
  width: 100% !important;
  margin: 8px;
  padding: 8px;
}

.header {
  align-items: flex-start;
  width: 100%;
  margin-block: 16px;
}

.success {
  background-color: rgb(246, 255, 237);
  border: 1px dashed rgb(183, 235, 143);
}

.wrong {
  background-color: rgb(255, 242, 240);
  border: 1px dashed rgb(255, 204, 199);
}

.selected {
  border: 1px solid rgb(145, 213, 255);
}

.analysis_row {
  margin: 8px;
  padding: 8px;
}
